const prod = {
  url: {
    KEYCLOAK_BASE_URL: "https://accounts.mycookingfeed.com",
    API_BASE_URL: 'https://api.mycookingfeed.com',
    BASE_URL: 'https://creators.mycookingfeed.com',
  }
}

const dev = {
  url: {
    KEYCLOAK_BASE_URL: "http://localhost:8091",
    API_BASE_URL: 'http://127.0.0.1:8070',
    BASE_URL: 'http://localhost:3000',
  }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod

export const XHR_SUCCESS = 'Download abgeschlossen';
export const XHR_FAILED = 'Download fehlgeschlagen';
export const XHR_CANCELED = 'Download abgebrochen';