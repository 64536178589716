import React, { createContext, useState, useContext } from "react";

const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
    const [triggerMessagesUpdate, setTriggerMessagesUpdate] = useState(true);

    return (
        <NotificationContext.Provider value={{ triggerMessagesUpdate, setTriggerMessagesUpdate }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);