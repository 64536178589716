import { config } from './Constants'
import { Dimmer, Header, Icon } from 'semantic-ui-react'
import { freshaApi } from './lib/FreshaApi'
import { HashRouter,Switch } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Route} from "react-router-dom";
import Home from './pages/Home.js'
import Keycloak from 'keycloak-js'
import React, { Component } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { NotificationProvider } from './provider/NotificationProvider';
import { TicketNotificationProvider } from './provider/TicketNotificationProvider';
import { ProfileProvider } from './provider/ProfileProvider';
import Logo from './assets/images/cookingpot-with-heart.png'
import CIcon from "@coreui/icons-react";
import LoadingSpinner from "./Components/creators/spinner/LoadingSpinner"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const keycloak = new Keycloak({
  url: `${config.url.KEYCLOAK_BASE_URL}`,
  realm: "Client",
  clientId: "admin-client"
})

const handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        // hier später user datem fetchen.. noch keine api vorhanden
        let response = await freshaApi.getBackendSitesVisible(keycloak.token)
        console.log(response)
        if (response.status === 400) {          
          keycloak.login()
        }
        keycloak.avatar = response.data.avatar
      }
    }
  }

const loadingComponent = (
  <Dimmer inverted active={true} page>
    <div style={{backgroundColor: "rgba(253,242,236,1)", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <CIcon name="logo" src={Logo} height="30" style={{height: "110px", margin: "auto"}} alt="Logo"/>
          <div style={{marginTop: "40px"}}>
            <LoadingSpinner></LoadingSpinner>
          </div>
        </div>

    </div>
  </Dimmer>
)

const initOptions = { pkceMethod: 'S256' }

const queryClient = new QueryClient()

class App extends Component {

  render() {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        LoadingComponent={loadingComponent}
        onEvent={(event, error) => handleOnEvent(event, error)}
      >
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <TicketNotificationProvider>
              <ProfileProvider>
                <HashRouter>
                  <React.Suspense fallback={loading}>
                    <Switch>
                      <Route path="/" render={props => <TheLayout {...props}/>} />
                    </Switch>
                  </React.Suspense>
                </HashRouter>
              </ProfileProvider>
            </TicketNotificationProvider>
          </NotificationProvider>
        </QueryClientProvider>
      </ReactKeycloakProvider>
    );
  }
}

export default App;