import React, { createContext, useState, useContext } from "react";

const ProfileContext = createContext(null);

export const ProfileProvider = ({ children }) => {
    const [triggerProfileUpdate, setTriggerProfileUpdate] = useState(true);

    return (
        <ProfileContext.Provider value={{ triggerProfileUpdate, setTriggerProfileUpdate }}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = () => useContext(ProfileContext);