import axios from 'axios'
import {config} from './../Constants'

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
  return response;
}, function (error) {
  if (error.response.status === 404) {
    return { status: error.response.status };
  }
  return Promise.reject(error.response);
});

export const freshaApi = {
  getRecipes,
  getIngredients,
  getTags,
  getSwitches,
  getNews,
  getNotifications,
  getCreatorNews,
  getNewsOfId,
  saveNews,
  newsShown,
  saveTag,
  saveIngredient,
  saveRecipe,
  getSimplifiedTags,
  getSimplifiedTagsForCategory,
  getSimplifiedIngredients,
  deleteIngredient,
  deleteRecipe,
  deleteTag,
  getCategories,
  saveCategory,
  deleteCategory,
  getCreators,
  deleteCreator,
  saveCreator,
  getCategory,
  getTag,
  getCreator,
  getLoggedInCreator,
  getIngredient,
  getRecipe,
  deleteImage,
  getAdminKeyData,
  getBackendSitesVisible,
  getCreatorProfile,
  getUserProfile,
  saveAmazonProducts,
  getAmazonProducts,
  deleteAmazonProduct,
  saveTicket,
  getOpenTickets,
  getClosedTickets,
  saveTicketMessage,
  closeTicket,
  viewTicket,
  openTicket,
  saveBankData,
  getSecuredBankData,
  deleteBankAccount,
  deleteNews,
  activateBankAccount,
  activateCreator,
  showCreator,
  deactivateCreator,
  hideCreator,
  activateSwitch,
  deactivateSwitch,
  activateRecipe,
  deactivateRecipe,
  getActiveUsersAnalyticsReportForCreator,
  getAnalyticsReportForTotalUsersOverall,
  getAnalyticsReportForTotalCreatorsOverall,
  getAnalyticsReportForTotalRecipesOverall,
  getAnalyticsGenderData,
  getAnalyticsDeviceData,
  getAnalyticsBacklingsBySource,
  getAverageClicksBacklinksForCreator,
  getAverageClicksBacklinksForCreatorLastAndThisMonth,
  getAnalyticsReportForCountrySessions,
  getAnalyticsReportForTopRecipesSessions,
  getBounceRatesForCreatorAndOverall,
  getMcfBacklinksReporting,
  getActiveUsersAnalyticsReportForMcf,
  getSessionsAnalyticsReportForMcf,
  getPagesPerSessionAnalyticsReportForMcf,
  getBounceRatesForMcf,
  markAllAnnouncementsAsSeen,
  getProfileInfo,
  uploadYoutubeCreatorsCsv,
  getCreatorsFilteredAndSorted,
  updateCreatorsAkquiseData,
  getTemplates,
  sendMail,
  updateCreatorMailComment,
  getCreatorsSearchForAcquise,
  initializeKeycloakAccount,
  isAdmin,
  getImagesFromUrl,
  getGenerateRecipeFromBlogUrl,
  postGenerateRecipeFromText,
  getGenerateRecipeFromYoutubeUrl,
  getGenerateRecipeFromInstagramUrl,
  getGenerateRecipesFromInstagramUrl,
  getInstagramId
}


function updateCreatorsAkquiseData(formData, token) {

  const params = new URLSearchParams();
  params.append('id', formData.id);
  params.append('email', formData.email);
  params.append('password', formData.password);
  params.append('name', formData.name);
  params.append('blog', formData.blog);
  params.append('hint', formData.hint);
  params.append('status', formData.status);
  params.append('phone', formData.phone);
  params.append('post', formData.post);
  params.append('title', formData.title);

  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/creators/akquise?`+ params, { headers });
}


function sendMail(formData, token) {

  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creator_mail/send`, formData, { headers });
}

function updateCreatorMailComment(token, id, comment) {

  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creator_mail/comment/` + id + "?comment=" + comment, { headers });
}

function initializeKeycloakAccount(id, token) {

  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/creators/akquise/keycloak?id=` + id, { headers });
}


function isAdmin(token) {

  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/admin`, { headers });
}


function getRecipes(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/recipes', { headers });
}

function uploadYoutubeCreatorsCsv(file, token){
  const formData = new FormData();
  formData.append('file', file);

  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/upload-csv/creators', formData, { headers });
}

function getIngredients(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/ingredients', { headers });
}

function getTags(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/tags', { headers });
}

function getTemplates(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/creator_mail/templates', { headers });
}

function getSwitches(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/switches', { headers });
}

function getNews(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/news', { headers });
}

function getNotifications(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/notifications', { headers });
}

function getCreatorNews(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/creator-news', { headers });
}

function getSecuredBankData(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/binfo/creator', { headers });
}

function getSimplifiedTags(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/tags/simplified', { headers });
}

function getSimplifiedTagsForCategory(token, category){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/tags/simplified/category/${category}`, { headers });
}

function getSimplifiedIngredients(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/ingredients/simplified', { headers });
}

function getOpenTickets(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/ticket/open', { headers });
}

function getClosedTickets(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/ticket/closed', { headers });
}

function saveTag(tag, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/tags', tag, { headers });
}

function saveBankData(bankData, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/binfo', bankData, { headers });
}

function saveTicket(ticket, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/ticket', ticket, { headers });
}

function saveTicketMessage(ticketId, ticketMessage, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/ticket-message/${ticketId}`, ticketMessage, { headers });
}

function closeTicket(ticketId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/ticket/close/${ticketId}`, null, { headers });
}

function viewTicket(ticketId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/ticket/view/${ticketId}`, null, { headers });
}

function openTicket(ticketId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/ticket/open/${ticketId}`, null, { headers });
}

function activateCreator(creatorId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creators/activate/${creatorId}`, null, { headers });
}

function deactivateCreator(creatorId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creators/deactivate/${creatorId}`, null, { headers });
}

function showCreator(creatorId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creators/show/${creatorId}`, null, { headers });
}

function hideCreator(creatorId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creators/hide/${creatorId}`, null, { headers });
}

function activateSwitch(switchId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/switches/activate/${switchId}`, null, { headers });
}

function deactivateSwitch(switchId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/switches/deactivate/${switchId}`, null, { headers });
}

function activateRecipe(recipeId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/recipes/activate/${recipeId}`, null, { headers });
}

function markAllAnnouncementsAsSeen(token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/notifications/news/seen`, null, { headers });
}

function deactivateRecipe(recipeId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/recipes/deactivate/${recipeId}`, null, { headers });
}

function saveIngredient(ingredient, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/ingredients', ingredient, { headers });
}

function saveRecipe(recipe, creatorId, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  let url = '/api/recipes';
  if(creatorId) {
    url += "/creator/" + creatorId;
  }

  return instance.post(url, recipe, { headers });
}

function saveNews(news, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/news', news, { headers });
}

function deleteNews(token, id) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/news/${id}`, null, { headers });
}

function newsShown(notificationDtos, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/notifications/shown', notificationDtos, { headers });
}

function deleteIngredient(ingredient, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/ingredients/${ingredient.id}`, ingredient, { headers });
}

function deleteAmazonProduct(product, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/amazon_products/${product.id}`, null, { headers });
}

function deleteBankAccount(token, id, type) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/binfo/delete/${type}/${id}`, null, { headers });
}


function activateBankAccount(token, id, type) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/binfo/activate/${type}/${id}`, null, { headers });
}

function deleteRecipe(recipe, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/recipes/${recipe.id}`, recipe, { headers });
}

function deleteCreator(creator, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/creators/${creator.id}`, null, { headers });
}

function deleteTag(tag, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/tags/${tag.id}`, tag, { headers });
}

function deleteCategory(category, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/categories/${category.id}`, null, { headers });
}

function deleteImage(image, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post(`/api/images/${image.id}`, null, { headers });
}

function getCategories(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/categories', { headers });
}

function getCreators(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/creators', { headers });
}


function getCreatorsFilteredAndSorted(token, {
  isChannelTitleWebsiteNotEmpty,
  isAmazonInDesc,
  isRecipeInDesc,
  isBlogInDesc,
  isWebsiteDesc,
  isFacebookDesc,
  isInstagramDesc,
  isWrittenRecipeDesc,
  isHiddenBlogUrl,
  isNotHiddenBlogUrl,
  sortBy,
  sortOrder,
  isHidden,
  isMailSend,
  isMailNotSend,
  isMailFailure,
  mailsSend,
  isEmail,
  isNotEmail,
  blogEntry,
  isPhone,
  isPost,
  isInstagramImport,
  isYoutubeImport,
  isTiktok,
  isStatus,
  page,
  size
}) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  // Prepare query parameters based on the provided filters and sorting options
  const params = {
    isChannelTitleWebsiteNotEmpty,
    isAmazonInDesc,
    isRecipeInDesc,
    isBlogInDesc,
    isWebsiteDesc,
    isFacebookDesc,
    isInstagramDesc,
    isWrittenRecipeDesc,
    isHiddenBlogUrl,
    isNotHiddenBlogUrl,
    sortBy,
    sortOrder,
    isHidden,
    isMailSend,
    isMailNotSend,
    isMailFailure,
    mailsSend,
    isEmail,
    isNotEmail,
    blogEntry,
    isPhone,
    isPost,
    isInstagramImport,
    isYoutubeImport,
    isTiktok,
    isStatus,
    page,
    size
  };

  // Remove undefined or null parameters to avoid sending them in the request
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

  return instance.get('/api/creators/filtered', { headers, params });
}

function getCreatorsSearchForAcquise(token, query) {

  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/creators/search/acquise?query='+query, { headers });
}

function getImagesFromUrl(token, url) {

  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/extract/images?url='+url, { headers });
}

function saveCategory(category, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/categories', category, { headers });
}

function saveAmazonProducts(products, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/amazon_products', { products }, { headers });
}

function getAmazonProducts(id, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/amazon_products/${id}`, { headers });
}

function getActiveUsersAnalyticsReportForCreator(startDate, endDate, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/creator?startDate=${startDate}&endDate=${endDate}`, { headers });
}

function getAnalyticsReportForTotalUsersOverall(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/total_users_over_overtime`, { headers });
}

function getAnalyticsReportForTotalCreatorsOverall(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/total_creators_overtime`, { headers });
}

function getAnalyticsReportForTotalRecipesOverall(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/total_recipes_overtime`, { headers });
}

function getAnalyticsGenderData(overallCall, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/gender?overall=` + overallCall, { headers });
}

function getAnalyticsDeviceData(overallCall, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/device?overall=` + overallCall, { headers });
}

function getAnalyticsBacklingsBySource(overallCall, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/backlinks_by_source?overall=` + overallCall, { headers });
}

function getAverageClicksBacklinksForCreator(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/average_clicks_backlinks`, { headers });
}

function getAverageClicksBacklinksForCreatorLastAndThisMonth(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/average_clicks_backlinks_compared`, { headers });
}

function getAnalyticsReportForCountrySessions(isOverAllCall, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/top_countries?overall=`+isOverAllCall, { headers });
}

function getAnalyticsReportForTopRecipesSessions(isOverAllCall, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/top_recipes?overall=`+isOverAllCall, { headers });
}

function getBounceRatesForCreatorAndOverall(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/bouncerate_compared`, { headers });
}

function getBounceRatesForMcf(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/bouncerate`, { headers });
}

function getMcfBacklinksReporting(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/backlinks_mcf`, { headers });
}

function getActiveUsersAnalyticsReportForMcf(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/active_users`, { headers });
}

function getSessionsAnalyticsReportForMcf(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/sessions`, { headers });
}

function getPagesPerSessionAnalyticsReportForMcf(token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/analytics/pages_per_session`, { headers });
}

function saveCreator(creator, token) {
  const headers = {
    'Content-type': 'application/json',
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.post('/api/creators', creator, { headers });
}

function getCategory(id, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/categories/${id}`, { headers });
}

function getTag(id, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/tags/${id}`, { headers });
}

function getCreator(id, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/creators/${id}`, { headers });
}


function getLoggedInCreator(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/creators/loggedIn`, { headers });
}

function getNewsOfId(id, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/news/${id}`, { headers });
}

function getIngredient(id, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/ingredients/${id}`, { headers });
}

function getRecipe(id, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/recipes/${id}`, { headers });
}

function getGenerateRecipeFromBlogUrl(blogUrl, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/ai/recipe/generate/blog?blogUrl=${blogUrl}`, {headers});
}

function getGenerateRecipeFromYoutubeUrl(youtubeUrl, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/ai/recipe/generate/youtube?youtubeUrl=${youtubeUrl}`, {headers});
}

function getGenerateRecipeFromInstagramUrl(instagramUrl, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/ai/recipe/generate/instagram?instagramUrl=${instagramUrl}`, {headers});
}

function getGenerateRecipesFromInstagramUrl(instagramUrl, creatorId, nrOfPosts, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/ai/recipes/generate/instagram?instagramUrl=${instagramUrl}&creatorId=${creatorId}&nrOfPosts=${nrOfPosts}`, {headers});
}

function getInstagramId(creatorId, token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get(`/api/creators/akquise/instagramid?creatorId=${creatorId}`, {headers});
}

function postGenerateRecipeFromText(text, token) {
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend',
    'Content-Type': 'application/json'
  };

  return instance.post('/api/ai/recipe/generate/text', text, { headers });
}

function getAdminKeyData(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/admin/keydata', { headers });
}

function getBackendSitesVisible(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/backend/sites', { headers });
}

function getCreatorProfile(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/creators/profile', { headers });
}

function getUserProfile(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/user/profile', { headers });
}

function getProfileInfo(token){
  const headers = {
    'Authorization': bearerAuth(token),
    'Referer-Client': 'backend'
  };

  return instance.get('/api/user/profile', { headers });
}

function bearerAuth(token) {
  return `Bearer ${token}`;
}
