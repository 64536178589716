import React, { createContext, useState, useContext } from "react";

const TicketNotificationContext = createContext(null);

export const TicketNotificationProvider = ({ children }) => {
    const [triggerTicketsUpdate, setTriggerTicketsUpdate] = useState(true);

    return (
        <TicketNotificationContext.Provider value={{ triggerTicketsUpdate, setTriggerTicketsUpdate }}>
            {children}
        </TicketNotificationContext.Provider>
    );
};

export const useTicketNotification = () => useContext(TicketNotificationContext);